import { useEffect, useState } from "react"
import myImage from "./images/studentCenter.jpg"

function Saaduddin() {

    const [name, setName] = useState([])

    useEffect(() => {
        const data = ["S", "Y", "E", "D", "S", "A", "A", "D", "U", "D", "D", "I", "N"];
        setName(data);
    }, []);


    let nameElements = name.map((ele, index) => (
        <span className={`name${index}`}>
            {ele}
        </span>
    ));

    const LastName = nameElements.slice(0, 4);
    const FirstName = nameElements.slice(4, nameElements.length);

    return (
        <>
            <div className="homepage">
                <div className="l1">
                    <div className="firstname">{FirstName} </div>
                    <div className="lastname">{LastName}</div>
                    <div className="leftBottom">
                        <ul>
                            <li>Hey, I'm Saaduddin Syed. I've have about 3+ years of experience working as Java full-stack developer, and it's been quite the journey. I love building software that people actually use, whether that's designing a clean, user-friendly frontend or making sure the backend can handle whatever we throw at it.</li>
                            <li>When it comes to backend development, I'm all about Java. I work a lot with Spring and Hibernate, creating APIs that are efficient and secure. On the frontend side, I'm pretty comfortable with Angular and React, and I enjoy making user interfaces that are both beautiful and intuitive.</li>
                            <li>Working with a team is something I really enjoy. I think collaboration makes us all better, so I like to jump into code reviews and contribute ideas. I'm also a big fan of agile processes, like stand-ups and sprints, because they keep everything organized and on track.</li>
                            <li>I'm always learning new things, whether it's a new technology or a better way to solve a problem. If you're looking for someone who can jump into a project and help build something great from start to finish, I'd love to be part of your team</li>
                        </ul>
                    </div>
                </div>

                <div className="r1">
                    <img src={myImage} alt="test" className="homeImg" />
                </div>
            </div>
        </>
    )
}

export default Saaduddin