import * as THREE from 'three'
import {Canvas,useLoader} from 'react-three-fiber'
import { Suspense} from 'react'
import { OrbitControls } from "@react-three/drei";



import csslogo from "./images/css.png"
import htmllogo from "./images/htmllogo1.jpeg"
import jslogo from "./images/jslogo1.jpeg"
import nodejslogo from "./images/nodejs.png"
import Clogo from "./images/C++.png"
import javalogo from "./images/javalogo1.png"
import plogo from "./images/plogo1.webp"
import reactlogo from "./images/React.png"
import expresslogo from "./images/expressjslogo2.png"
import mongoDBlogo from "./images/mdblogo.jpg"



function Mesh({tex,position,color }){
    // I have to use texture loader to use image in 3d
    const logo = useLoader(THREE.TextureLoader, tex);
    

    return(
//         Mesh( geometry : BufferGeometry, material : Material )
// geometry — (optional) an instance of BufferGeometry. Default is a new BufferGeometry.
// material — (optional) a single or an array of Material. Default is a new MeshBasicMaterial
//mesh is a class in three.js doc which accepts buffergeometry(polygons) and material
    <mesh position={position}>
        <boxGeometry args={[2, 2, 2]}/>
        <meshBasicMaterial
            attach="material"
            map={logo}
            />
        <OrbitControls />
    </mesh>
    )
}



function Animate(){
return(
<>
<Canvas 
   style={{ height: '800px', width: '900px' }} 
   camera={{ position: [4, 4, 12] }}
   >

        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
            <Mesh tex={javalogo} position={[-2, 0, 0]} />
            <Mesh tex={nodejslogo} position={[-2,0,4]}/>

            <Mesh tex={Clogo} position={[2, 0, 0]}/>
            <Mesh tex={htmllogo} position={[2,0,4]}/>

            <Mesh tex={expresslogo} position={[5,-2,2]}/>
            <Mesh tex={mongoDBlogo} position={[-5,-2,2]}/>

            <Mesh tex={plogo} position={[2,-4,0]}/>
            <Mesh tex={csslogo} position={[2,-4,4]}/>

            <Mesh tex={jslogo} position={[-2,-4,0]}/>
            <Mesh tex={reactlogo} position={[-2,-4,4]}/>
           
          
        </Suspense>
   </Canvas>
</>

)
}

function Skill(){
    return(
        <>
        <div className="SkillFlexContainer">
        <div className='writtenPart'>
                <h2>Programming Skills </h2>
                    <ul className='list1'>
                        <li>C++</li>
                        <li>Python</li>
                        <li>Javascript</li>
                        <li>Java</li>    
                    </ul>

                <h2>Frontend development Skills</h2>
                    <ul className='list1'>
                        <li>Html</li>
                        <li>Css</li>
                        <li>React</li>
                        <li>Angular</li>
                    </ul>
                
                <h2>Backend development Skills</h2>
                    <ul className='list1'>
                    <li>Spring Boot</li>
                    <li>MySQL</li>
                    <li>Node JS</li>
                    <li>MongoDB</li>
                    </ul>
            </div>

            <div className="circle">
                <div className='line'>
                
                </div>
            </div>


            <div className="ProgrammingSkillBar">
            <h3>Grab the object and rotate to see other cubes. You can minimize and maximize the cubes as well.</h3>
                <Suspense fallback={<div>...Loading</div>}>
                    <Animate/>
                </Suspense>       
            </div>

        </div>

      
          
        </>
      
    )
}

export default Skill;