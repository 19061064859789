import indium from "./images/indiumsoftware_logo.jpeg"
import Niulogo from "./images/NIUlogo.jpeg"
function Experience() {
    return (
        <div className="educationContainer">
            <div className="eduLeftpart">
                <img src={Niulogo} alt="bachelors" className="niuExp" />
                <img src={indium} alt="masters" className="BootcampExp" />
            </div>
            <div className="eduRightpart">

                <div className="part1">
                    <h2>NORTHERN ILLINOIS UNIVERSITY</h2>
                    <div className="dangerExp">
                        <h3>Mathematics Teaching Assistant : </h3>
                        <p><li>As a Graduate Teaching Assistant I was responsible for tutoring Mathematics
                            concepts to the students such as Calculus, Algebra, Probability,
                            Statistics etc., and grading the students for their course work.</li></p>
                    </div>
                    <div>
                        <h3>Aug 2022 - May 2023</h3>
                    </div>
                </div>


                <div className="part2">
                    <h2>INDIUM TECHNOLOGIES</h2>
                    <div className="fd">
                        <h3>Java UI developer :</h3>
                        <p><li>Developed presentation layer and GUI Framework using CSS3&HTML5 and Client-Side validations were done using JavaScript and some server-side validations with Java.
                        </li> <li>Experienced in developing web-based applications using Java/J2EE, Servlets, Spring IOC, Spring MVC,Spring AOP, Spring Security, Spring Boot, Web Services (RESTful services) and ORM frameworks like Hibernate.</li> 
                        </p>
                    </div>
                    <div>
                        <h3>Dec 2018 - Dec 2021</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience