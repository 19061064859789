import osmanialogo from "./images/osmaniaUni.png"
import Niulogo from "./images/NIUlogo.jpeg"
import {Link} from "react-router-dom"

function Education(){
    return(
        <div className="educationContainer">
            <div className="eduLeftpart">
            <img src={osmanialogo} alt="bachelors" className="engg" />
            <img src={Niulogo} alt="masters" className="grad" />
            </div>
            <div className="eduRightpart">
           
                <div className="p1">
                <Link to="https://www.osmania.ac.in/" style={{textDecoration: 'none'}} target="_blank"><h2>OSMANIA UNIVERSITY</h2></Link>

                        <div className="danger">
                            <h3>Engineering :</h3>
                            <p>Bachelor's of Engineering in Electronics and Communication</p>
                        </div>
                      
                </div>
            
                <div className="p2">
                <Link to="https://www.niu.edu/index.shtml" style={{textDecoration: 'none'}} target="_blank"><h2>NORTHERN ILLINOIS UNIVERSITY</h2></Link>
                    <div className="danger2" >
                        <h3>Master's :</h3>
                        <p>Computer Science</p>
                    </div>
               
                </div>
            </div>
        </div>
    )
}

export default Education